/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #545454 #b4b4b4;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 4px;
	height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #d5d5d5;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #838383;
    border-radius: 10px;
    border: 1px solid #878787;
  }